<template>
  <div class="push-message">
    <PageTitle
      icon="chevron_left"
      title="訊息分群推播"
      hideBtn
      style="margin-bottom: 24px"
      @iconClick="$router.go(-1)"
    />

    <!-- 訊息 -->
    <section>
      <SectionTitle title="訊息" hideBtn fontSize="18" />
      <MessageBlock
        v-for="(block, index) in notifyMessageStack"
        :key="`block-${index}`"
        class="mb-3"
        :model.sync="notifyMessageStack[index]"
        :closeBtn="notifyMessageStack.length > 1"
        maxContent="500"
        :aiHint="true"
        @error="onError = true"
        @checked="onError = false"
        @close="removeNotifyMessage(index)"
      />

      <AddButton @click="addNotifyMessage" />
    </section>

    <!-- 對象 -->
    <section>
      <SectionTitle title="對象" hideBtn fontSize="18" />
      <BaseElRadioGroup v-model="allMember" @change="tagChange">
        <div class="radio-group" @change="changeCondition">
          <BaseElRadio :label="true" class="flex">
            <p class="text">所有會員</p>
            <p v-if="allMember" class="count">
              預計發送: {{ count.count }}人 <span class="text-gray-80">(已扣除未綁定Line會員)</span>
            </p>
          </BaseElRadio>
          <BaseElRadio :label="false" class="flex">
            <p class="text">進階標籤會員</p>
            <p v-if="!allMember">
              <template v-if="tagMemberCount.isAccurate">
                預計發送: {{ tagMemberCount.count }}人 <span class="text-gray-80">(已扣除未綁定Line會員)</span>
              </template>
              <template v-else>
                預計發送約 {{ tagMemberCount.count }}人 ({{ tagMemberCount.countConfidenceMin }}~{{ tagMemberCount.countConfidenceMax }}人) <span class="text-gray-80">(已扣除未綁定Line會員)</span>
              </template>
            </p>
          </BaseElRadio>
        </div>
      </BaseElRadioGroup>

      <div v-if="!allMember" class="mt-3">
        <div
          v-for="(block, index) in conditions"
          :key="`block-${index}`"
          class="flex flex-col"
        >
          <div class="tag-search-container">
            <div class="flex flex-col gap-[10px]">
              <span>完全包含</span>
              <div class="flex items-center gap-[10px]">
                <TagSearch
                  :model.sync="block.includes"
                  objKey="id"
                  multiple
                  :tags="tags"
                  :preserve-null="true"
                  @update:model="tagChange('includes', $event)"
                />
                <i
                  v-if="conditions.length > 1"
                  class="el-icon-circle-close close-btn"
                  @click="removeBlock(index)"
                />
              </div>
            </div>

            <div class="flex flex-col gap-[10px]">
              <span>排除</span>
              <div class="flex gap-[10px]" style="align-items: center;">
                <TagSearch
                  :model.sync="block.excludes"
                  :tags="tags"
                  objKey="id"
                  multiple
                  :preserve-null="true"
                  @update:model="tagChange('excludes', $event)"
                />
              </div>
            </div>
          </div>

          <div
            v-if="conditions.length > 1 && index < conditions.length - 1"
            class="m-2"
            style="color: #2BB8C1;"
          >
            or
          </div>
        </div>

        <AddButton @click="addCondition" />
      </div>
    </section>
    <!-- 時間 -->
    <section>
      <SectionTitle title="時間" hideBtn fontSize="18" />
      <BaseElRadioGroup v-model="immediate">
        <div class="radio-group">
          <BaseElRadio :label="true">立即發送</BaseElRadio>
          <BaseElRadio :label="false">排程單次推播</BaseElRadio>

          <div v-if="!immediate" class="flex gap-10">
            <el-date-picker
              v-model="dateTime.date"
              editable
              type="date"
              placeholder="選擇日期"
              @change="dateChange"
            />
            <el-time-select
              v-model="dateTime.time"
              :picker-options="{
                minTime,
                start: '00:00',
                step: '00:15',
                end: '24:00',
              }"
              placeholder="選擇時間"
            />
          </div>
        </div>
      </BaseElRadioGroup>
    </section>

    <PageFixedFooter
      confirmBtn="發送"
      :confirmLoading="sending"
      @cancel="onCancel"
      @confirm="sendMessage"
    />
  </div>
</template>

<script>
import { Estimate, CreatePushMessage } from '@/api/pushMessage'
import TagSearch from '@/components/Search/TagSearch'
import MessageBlock from '@/components/Message/MessageBlock.vue'
import { mapGetters } from 'vuex'
import dayjs from '@/lib/dayjs'
import { isInvalidDate } from '@/utils/date'
import { useTagStore } from './components/useTagStore'
import { defineComponent, getCurrentInstance, onMounted, computed, ref, nextTick, onBeforeUnmount } from 'vue'
import { useStorage } from '@vueuse/core'
import { KEY_MEMBER_TAG_PUSH_MESSAGE } from '@/utils/localstorage'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router/composables'
import { find, filter, omit, map } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'PushMessage',
  components: { TagSearch, MessageBlock },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { shopId } = useShop()
    const { getAllTags, tags } = useTagStore()
    const tagQuery = computed(() => route.query.tagName)
    const tagsAdvancedSearch = computed(() => route.query.tagsAdvancedSearch)
    const tagMemberCount = ref(0)
    const allMember = ref(true)
    const conditions = useStorage(KEY_MEMBER_TAG_PUSH_MESSAGE,
      [{
        includes: [],
        excludes: [],
      }],
      sessionStorage)
    // const conditions = ref([
    //   {
    //     includes: [],
    //     excludes: [],
    //   },
    // ])

    const estimate = async () => {
      const res = await Estimate({
        shopId: shopId.value,
        object: 'lineMember',
        conditions: conditions.value,
      })

      return res
    }

    onMounted(async () => {
      await getAllTags()

      if (tagQuery.value) {
        const exist = find(tags.value, { name: tagQuery.value })
        if (exist) {
          allMember.value = false
          conditions.value[0].includes.push(exist.id)
          tagMemberCount.value = await estimate()
        }
      }
      if (tagsAdvancedSearch.value) {
        allMember.value = false
        tagMemberCount.value = await estimate()
      }
    })

    const onCancel = () => {
      conditions.value = [{
        includes: [],
        excludes: [],
      }]
      router.go(-1)
    }

    onBeforeRouteLeave((to, from, next) => {
      conditions.value = [{
        includes: [],
        excludes: [],
      }]
      next()
    })

    return {
      tags,
      conditions,
      estimate,
      allMember,
      tagMemberCount,
      onCancel,
    }
  },
  data () {
    return {
      sending: false,
      onError: false,
      content: '',
      immediate: true,
      // allMember: true,
      count: 0,
      // tagMemberCount: 0,

      dateTime: {
        date: new Date(),
        time: '00:00',
      },

      notifyMessageStack: [
        {
          type: 'text',
          content: '',
          action: undefined,
          actionContent: undefined,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['shop']),
    expectSendAt () {
      const year = dayjs(this.dateTime.date).year()
      const month = dayjs(this.dateTime.date).month()
      const date = dayjs(this.dateTime.date).date()
      const time = this.dateTime.time.split(':')
      const dateTime = new Date(year, month, date, time[0], time[1])
      return isInvalidDate(dateTime) ? new Date() : dateTime
    },
    minTime () {
      const isAfterDate = dayjs(this.dateTime.date).isAfter(dayjs())
      if (isAfterDate) return ''
      const now = dayjs().format('HH:mm')
      return now
    },
    compactConditions () {
      return filter(this.conditions, (condition) => {
        return condition.includes.length || condition.excludes.length
      })
    },
  },

  async mounted () {
    this.count = await this.estimate()
    this.dateChange()
  },

  beforeRouteEnter (to, from, next) {
    console.log(from.name)
    if (from.name !== 'TagsAdvancedSearch') {
      sessionStorage.setItem(KEY_MEMBER_TAG_PUSH_MESSAGE, JSON.stringify([{
        includes: [],
        excludes: [],
      }]))
    }
    next()
  },

  methods: {
    dateChange (date) {
      const isSameDate = dayjs(this.dateTime.date).isSame(dayjs(), 'date')
      let [hour, min] = this.minTime.split(':')

      if (min === '00') min = '00'
      else if (+min < 15) min = '15'
      else if (+min < 30) min = '30'
      else if (+min < 45) min = '45'
      else if (+min > 45) {
        if (hour !== '24') {
          hour = Number(hour) + 1
          min = '00'
        }
      }

      if (isSameDate) {
        const time = `${hour}:${min}`
        this.dateTime.time = time
      }
    },
    changeCondition () {
      // this.conditions = [
      //   {
      //     includes: [],
      //     excludes: [],
      //   },
      // ]
      this.conditions = useStorage(KEY_MEMBER_TAG_PUSH_MESSAGE, [{
        includes: [],
        excludes: [],
      }], sessionStorage)
    },
    // async estimate () {
    //   const res = await Estimate({
    //     shopId: this.shop,
    //     object: 'lineMember',
    //     conditions: this.conditions,
    //   })

    //   return res
    // },

    async tagChange (changeType, data) {
      // if(changeType === 'includes') {
      //   this.conditions = data
      // } else if (changeType === 'excludes') {
      //   this.conditions = data
      // }
      await nextTick()
      console.log('🔥[debug] tagChange: ', changeType, data)
      this.tagMemberCount = await this.estimate()
    },

    addCondition () {
      this.conditions.push({
        includes: [],
        excludes: [],
      })
    },

    removeBlock (index) {
      this.conditions.splice(index, 1)
    },

    removeNotifyMessage (index) {
      this.notifyMessageStack.splice(index, 1)
    },

    addNotifyMessage () {
      this.notifyMessageStack.push({
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
      })
    },

    //= > 創建推播
    async createPushMessage () {
      const notifyMessageStack = map(this.notifyMessageStack, item => omit(item, ['Image']))

      try {
        const res = await CreatePushMessage({
          shopId: this.shop,
          messages: notifyMessageStack,
          expectSendAt: this.immediate ? null : this.expectSendAt,
          conditions: this.compactConditions,
        })
        return res.id
      } catch (error) {
        console.log(error)
        this.$message.error(error)
        return false
      }
    },

    //= > 發送推播控制
    async sendMessage () {
      this.sending = true
      if (this.onError) {
        this.sending = false
        return
      }
      if (
        this.notifyMessageStack.length === 1 &&
        this.notifyMessageStack[0].content === '' &&
        this.notifyMessageStack[0].actionContent === undefined
      ) {
        this.$message.warning('至少需要一則訊息，請輸入內容')
        this.sending = false
        return
      }
      try {
        if (!await this.createPushMessage()) {
          this.sending = false
          return
        }
        this.resetMessage()
        this.$message.success('已發送推播訊息 !')
        this.$router.push({
          name: 'PushMessageSetting',
          query: {
            tab: 'message',
          },
        })
        this.sending = false
      } catch (error) {
        console.log(error)
        this.sending = false
        this.$message.error(error)
      }
    },

    resetMessage () {
      this.conditions = [
        {
          includes: [],
          excludes: [],
        },
      ]

      this.notifyMessageStack = [
        {
          type: 'text',
          content: '',
          action: undefined,
          actionContent: undefined,
        },
      ]
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-radio__input {
  @apply py-[5px];
}
.push-message {
  @apply pb-[100px];
}
.title {
  font-size: 24px;
  margin-bottom: 10px;
  .hint {
    @apply text-primary-100 mr-[10px];
  }
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .text {
    @apply mr-[10px] leading-[24px];
  }
}

.tag-search-container {
  @apply flex flex-col;
  // align-items: flex-end;
  gap: 10px;
  margin: 10px 0;

  .close-btn {
    @apply cursor-pointer;
    font-size: 20px;
  }
}
</style>
